/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  font-style: normal;
  font-weight: normal;
  src: url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-regular.woff2') format('woff2'),
  url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-regular.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  font-style: normal;
  font-weight: bold;
  src: url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-bold.woff2') format('woff'),
  url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-bold.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  font-style: normal;
  font-weight: 600;
  src: url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-semibd.woff2') format('woff2'),
  url('https://djmnx1dsj7ttn.cloudfront.net/fonts/harmoniasansprocyr-semibd.woff') format('woff');
  font-display: fallback;
}


* {
  font-family: 'Harmonia Sans Pro Cyr', "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif ;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './../node_modules/animate.css/animate';
@import './../node_modules/ngx-toastr/toastr';

@import "./shared/components/molecules/table/style.scss";
@import "./typography";

.ngx-toastr {
  &.success {
    @apply bg-success-600 text-white;
  }

  &.error {
    @apply bg-danger text-white;
  }

  &.info {
    @apply bg-info text-white;
  }

  &.warning {
    @apply bg-warning text-white;
  }
}

@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "../node_modules/flatpickr/dist/flatpickr.css";
.flatpickr-calendar:before, .flatpickr-calendar:after {
  content: none;
}

.flatpickr-monthDropdown-month {
  @apply text-base;
}

.flatpickr-calendar {
  max-width: 350px;
  min-width: 250px;
}
.text-pretty {
  text-wrap: pretty;
}

.flatpickr-days, .dayContainer {
  max-width: 350px !important;
  min-width: auto !important;
  width: 100% !important;
}

.flatpickr-day {
  max-width: 100% !important;
  width: 100% !important;
}

.flatpickr-day.selected {
  @apply bg-dark border-dark
}

[hidden] {
  display: none !important;
}
