@layer base {
  html {
    font-size: 14px;
  }
}
body {
  font-size: 14px;
}
h1 {
  @apply text-xl text-dark font-semibold leading-[22px] tracking-[.5px];
}

h2 {
  @apply text-md font-semibold leading-6 tracking-[.5px];
}

h5 {
  @apply text-base font-semibold leading-4 tracking-[.5px] text-primary;
}

p {
  @apply text-base font-normal leading-4 tracking-[.75px];
}

label {
  @apply text-base leading-normal #{!important};
}

input {
  @apply text-base;
}
